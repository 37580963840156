// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

const Turbolinks = {
  visit: function (location, options) {
    window.location = location;
  },
  clearCache: function () {
    // window.Turbolinks.clearCache();
  },
};

window.Turbolinks = Turbolinks;

$(document).on("page:load", function (e) {
  window.scrollTo(0, 0);
});

require("@rails/ujs").start();
// require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");
require("inputmask/dist/jquery.inputmask");
// chanells
require("channels/index");
require("channels/consumer");
require("channels/messages");
require("channels/notifications");
// custom
require("custom/common");
require("custom/masks");
require("custom/sessions");
require("custom/file-upload");
require("custom/forms");
require("custom/messages");

import "../css/application.css";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import "trix/dist/trix.css";
require("trix");
require("@rails/actiontext");
