import consumer from "./consumer"

function format_date(date) {
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  
  return monthNames[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();
};

function render_last_messages(messaging_threads) {
  messaging_threads.forEach(function(item) {
    if (item.notifications_count > 0) {
      $("#js--messages-count-" + item.id).show();
      $("#js--messages-count-" + item.id).html(item.notifications_count);
      $("#js--last-message-" + item.id + " .js--last-message-datetime").html(format_date(new Date(item.last_message.created_at)));
      $("#js--last-message-" + item.id + " .js--last-message-body").html(item.last_message.body);
    }
  })
};

function subscribe_notifications(room_id) {
    if (window.matchMedia("(max-width: 768px)").matches) {
        window.scrollTo(0,document.body.scrollHeight);
    }
    var Notifications = consumer.subscriptions.create(
      {
          channel: "NotificationsChannel",
          notifications_stream_id: room_id
      },
      {
          connected: function() {
              console.log("connected to notifications_channel "+ room_id);
              // Called when the subscription is ready for use on the server
          },

          disconnected: function() {
              console.log("disconnected from notifications_channel");
              // Called when the subscription has been terminated by the server
          },

          received: function(data) {
              // Called when there's incoming data on the websocket for this channel
              if (data.messages_count > 0) {
                  $(".js--message-notifications").show();
                  if ($(".js--messaging-threads").length > 0) {
                    render_last_messages(data.messaging_threads);
                  }
              } else {
                  $(".js--message-notifications").hide();
              }
              if (data.jobs_count > 0) {
                  $(".js--job-notifications").show();
              } else {
                  $(".js--job-notifications").hide();
              }
          }
      }
    );
    setInterval(function() { Notifications.send({'id': room_id}); }, 10000);
};

$(document).ready(function(){
    if ($(".js--notifications").length > 0) {
        subscribe_notifications($(".js--notifications").data("id"))
    }
});
