import Placeholder from '../images/sample-doc-blank.png'

$(document).ready(function(){
  $(document).on('change', 'input[type="file"]', function(e) {
    const node = this;
    const files = node.files;
    const element_id = $(node).attr('id');
    const element_name = $(node).attr('name');
    if(files.length > 0){
      const rand = Math.floor(Math.random() * 1000);
      $(node).attr({"required": false, id: `${element_id}_${rand}`});
      const newFile = $('<input />')
        .attr('type', 'file')
        .attr('name', element_name)
        .attr('id', element_id)
        .attr('multiple', 'multiple')
        .addClass('hidden')
      newFile.insertAfter(node);
      // const newFiles = $(node).clone(true).attr("id", `${element_id}_${rand}`).insertAfter(node);
      imagesPreview(node, `show_${element_id}`);
    }
  });

  $(document)
    .on("ajax:success",'.js-delete-file', (event) => {
      const [data, status, xhr] = event.detail;
      if (data.status === "ok") {
        location.reload();
      }
    })
});

const isFileImage = (file) => {
  return file && file['type'].split('/')[0] === 'image';
};

const imagesPreview = (node = [], placeToInsertImagePreview) => {
  
  if (node.files.length > 0) {
    const isProfilePic = placeToInsertImagePreview.includes('profile_picture') ||  placeToInsertImagePreview.includes('company_logo');
    $('#file_placeholder').hide();
    const filesAmount = node.files.length;
    // $(`#${placeToInsertImagePreview}`).html('');
    for (let i = 0; i < filesAmount; i++) {
      if (isFileImage(node.files[i])) {
        const reader = new FileReader();
        reader.onload = function(e) {
          const newImage = $($.parseHTML('<img>'))
            .attr({
              src: e.target.result,
              width: '200',
              height: '200',
              "class": `mb-4 mr-4 ${isProfilePic ? "avatar-xxl" : "w-64"}`
            });
          if (isProfilePic) $(`#${placeToInsertImagePreview}`).html(newImage)
          else newImage.appendTo(`#${placeToInsertImagePreview}`);
        };
        reader.readAsDataURL(node.files[i]);
      } else {
        $(`#${placeToInsertImagePreview}`).append(`<div class="mb-4 mr-4 w-64 relative"><img src="${Placeholder}" class="mb-4 mr-4 w-64"/><div class="absolute inset-0 flex items-center justify-center text-center font-bold text-lg break-all w-11/12 mx-auto">${node.files[i].name}</div></div>`)
      }
    }
  }
};