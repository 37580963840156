$(document).ready(function () {
  // $(document).on('focus', "#driver_profile_license_number", function(){
  //   $(this).inputmask("A9999-99999-99999");
  // })
  $(document).on("focus", "#driver_profile_license_exp_date", function () {
    $(this).inputmask({
      alias: "datetime",
      inputFormat: "yyyy/mm/dd",
      placeholder: "YYYY-MM-DD",
    });
  });

  $(document).on("focus", "#driver_profile_dob", function () {
    $(this).inputmask({
      alias: "datetime",
      inputFormat: "yyyy/mm/dd",
      placeholder: "YYYY-MM-DD",
    });
  });

  // $('#driver_phone_number').inputmask("[(999) 999-9999]",{ removeMaskOnSubmit: true, "clearIncomplete": true});
  // $('#driver_email').inputmask({
  //   mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
  //   greedy: false,
  //   onBeforePaste: function (pastedValue, opts) {
  //     pastedValue = pastedValue.toLowerCase();
  //     return pastedValue.replace("mailto:", "");
  //   },
  //   definitions: {
  //     '*': {
  //       validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
  //       casing: "lower"
  //     }
  //   }
  // });
});
