import { createConsumer } from "@rails/actioncable"

function validateMessage() {
  $("#new-message__validation-errors").html("");
  $("#new-message__validation-errors").hide();
  var safe = true;

  if ($(".js--message-input").val() == "") {
    safe = false;
  }

  if (!safe) {
    $("#new-message__validation-errors").html("A message is required.");
    $("#new-message__validation-errors").show();
  }

  if (safe) {
    if ($(".js--message-form").length > 0) {
        $(".js--message-form").submit();
        $(".js--message-input").val("");
    }
  }
};

$(document).ready(function() {
    $("#new-message__validation-errors").hide();

    $("#new-message__submit").on("click", function(e) {
        e.preventDefault();
        validateMessage();
    });
});
