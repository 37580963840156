$(document).ready(function(){
  $(document).on('click', '.js-dismiss_parent', function(){
    let node = $(".announcement-success")
    node = node[0] || node;
    node.classList.add("hidden");
  });

  $(document).on('click', '.modal-open', function(event){
    event.preventDefault()
    const modalId = $(this).data('target')
    toggleModal(modalId)
  });

  $(document).on('click', '.modal-overlay', function(event){
    event.preventDefault()
    const modalId = $(this).data('target')
    toggleModal(modalId)
  });

  $(document).on('click', '.modal-close', function(event){
    event.preventDefault()
    const modalId = $(this).data('target')
    toggleModal(modalId)
  });

  $(document).on('click', '.modal-submit', function(event){
    event.preventDefault()
    $(this).addClass("opacity-50 cursor-not-allowed");
    const form_id = $(this).data('target');
    $(form_id).submit();
  });
  
  document.onkeydown = function(evt) {
    evt = evt || window.event
    var isEscape = false
    if ("key" in evt) {
      isEscape = (evt.key === "Escape" || evt.key === "Esc")
    } else {
      isEscape = (evt.keyCode === 27)
    }
    if (isEscape && document.body.classList.contains('modal-active')) {
      toggleModal()
    }
  };
  
  
  function toggleModal (id) {
    $(id).toggleClass("opacity-0");
    $(id).toggleClass("pointer-events-none");
  }
});